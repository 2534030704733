import * as React from 'react';

import styled from 'styled-components';

import BREAKPOINTS from 'styles/breakpoints';
import Button from './Button';
import { useBookingFlowManager } from 'context/bookingFlow';

const Footer = styled.nav`
  display: none;

  ${BREAKPOINTS.medium`
    width: 100%;
    display: flex;
    margin-top: 1.5rem;
    justify-content: space-between;
    padding: 0 1.5rem;
  `}
`;

const NextButton = styled(Button)`
  margin-left: auto;
`;

const BookingFooterDesktop = () => {
  const {
    shouldShowNextButton,
    nextButtonDisabled,
    nextButtonText,
    onNextStep,
    send,
    shouldShowBackButton,
  } = useBookingFlowManager();
  return (
    <Footer>
      {shouldShowBackButton && (
        <Button
          onClick={() => {
            send({ type: 'onPrevStep' });
          }}
          theme="blue"
        >
          Back
        </Button>
      )}
      {shouldShowNextButton && (
        <NextButton onClick={onNextStep} disabled={nextButtonDisabled}>
          {nextButtonText}
        </NextButton>
      )}
    </Footer>
  );
};

export default BookingFooterDesktop;
