const zIndex = {
  navBarLogo: 120,
  navBar: 110,
  bookButtonDesktop: 100,
  burgerMenu: 99,
  mobileNavOverlay: 30,
  modalOverlay: 200,
};

export default zIndex;
