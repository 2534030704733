import NextLink from 'next/link';
import React from 'react';
import styled, { css } from 'styled-components';

import COLORS from 'styles/colors';
import { weight } from 'styles/typography';

const linkStyle = css`
  font: inherit;
  font-weight: ${weight.semibold};
  color: ${COLORS.dawn};
  text-decoration: underline;
`;

const A = styled.a`
  ${linkStyle}
`;

const Button = styled.button`
  ${linkStyle}
`;

type LinkProps = Omit<React.ComponentProps<typeof NextLink>, 'href'> & {
  targetBlank?: boolean;
  className?: string;
  href: string;
};

export const Link = (props: LinkProps) => {
  const { targetBlank, className, children, href, ..._props } = props;
  const ref = React.useRef<HTMLAnchorElement>(null);
  return (
    <a
      ref={ref}
      href={href}
      target={targetBlank ? '_blank' : '_self'}
      rel="noreferrer"
      className={className}
      {..._props}
    >
      {children}
    </a>
  );
};

type LinkButtonProps = Omit<React.ComponentProps<typeof Button>, 'href'> & {};

export const LinkButton = (props: LinkButtonProps) => {
  return <Button {...props} />;
};
