import styled from 'styled-components';
import COLORS from 'styles/colors';

const StyledBookingFlowButton = styled.button`
  display: flex;
  padding: 0.5rem 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
  background: ${COLORS.dawn};
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  :focus-visible {
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const BookingFlowButton = ({ children, onClick, ...props }) => {
  return (
    <StyledBookingFlowButton onClick={onClick} {...props}>
      {children}
    </StyledBookingFlowButton>
  );
};

export default BookingFlowButton;
