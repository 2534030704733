/* eslint-disable no-console */
import * as React from 'react';
import styled, { css } from 'styled-components';
import FocusTrap from 'focus-trap-react';

import { useBookingSearch } from 'context/BookingSearchProvider';
import { useNav } from 'context/NavProvider';

import BookingFlowButton from '../Button/BookingFlowButton';
import ClaysLogoSVG from '../ClaysLogoSVG';
import Accordion from './Accordion';
import ErrorSvg from 'public/images/icons/error.svg';

import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';

// --- SEARCH BAR STYLES ---

const SearchBar = styled.button`
  display: flex;
  align-items: center;
  height: 2.9375rem;
  width: 12.5rem;
  background-color: ${COLORS.white};
  padding: 0.19rem;
  border: none;
  cursor: pointer;
  border-radius: 3.27006rem;
  box-shadow: 1px 1px 8px 0px ${COLORS.darkGrey.opacity(0.5)};

  &:focus-visible {
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

const MakeABooking = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  color: ${COLORS.darkGrey};
  font-family: ${FONTS.poppins};
  font-size: 0.875rem;
  font-weight: 600;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.dawn};
  border-radius: 50%;
  width: 2.43756rem;
  height: 2.43756rem;
  flex-shrink: 0;
`;

const MagnifyingGlassIcon = styled.img``;

// --- MODAL STYLES ---

const FooterHeight = '5.5625rem';

const Modal = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100dvh;
  background-color: ${COLORS.sky};
  z-index: 1000;
  overflow: hidden;
`;

const ModalContent = styled.div`
  flex-grow: 1;
  padding: 1.25rem;
  padding-bottom: ${FooterHeight};
  overflow-y: auto;
`;

const Header = styled.header`
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  & h3 {
    font-family: ${FONTS.wulkan};
    color: ${COLORS.forest};
    text-align: center;
    font-size: 1.5625rem;
    font-weight: 850;
    line-height: 1rem;
  }
`;

const ButtonSize = '3rem';

const CloseButton = styled.button`
  height: ${ButtonSize};
  width: ${ButtonSize};
  background-image: url('/images/icons/cross.svg');
  background-size: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;

  &:focus-visible {
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

const Footer = styled.footer`
  height: 5.5625rem;
  width: 100%;
  padding: 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.white};
`;

const ClearButton = styled.button`
  display: flex;
  width: 4.25rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  color: ${COLORS.darkGrey};
  text-align: center;
  font-family: ${FONTS.poppins};
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-decoration-line: underline;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration-line: none;
  }

  &:focus-visible {
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 1rem;
  justify-content: flex-end;

  ${({ error }) =>
    error &&
    css`
      justify-content: space-between;
    `}
`;

const ErrorMessage = styled.div`
  height: 1.5rem;
  display: flex;
  align-items: center;
  color: ${COLORS.orange};
  font-size: 0.8125rem;
`;

const SearchBarMobile = () => {
  const { isSearchModalOpen, setSearchModalOpen } = useNav();
  const { searchBarConfig, clearSearchData, searchErrors, submitSearchData } =
    useBookingSearch();

  const toggleSearchModal = () => {
    setSearchModalOpen(!isSearchModalOpen);
  };

  const [activeAccordion, setActiveAccordion] = React.useState('Where');

  const toggleAccordion = (title) => {
    setActiveAccordion(activeAccordion === title ? '' : title);
  };

  return (
    <>
      <SearchBar
        onClick={toggleSearchModal}
        aria-label="Open booking search"
        tabIndex="0"
      >
        <MakeABooking>
          <span>Make a booking</span>
        </MakeABooking>
        <IconContainer>
          <MagnifyingGlassIcon
            src="/images/icons/magnifying-glass.svg"
            alt=""
          />
        </IconContainer>
      </SearchBar>
      {isSearchModalOpen && (
        <FocusTrap active={isSearchModalOpen}>
          <Modal isOpen={isSearchModalOpen}>
            <ModalContent>
              <Header>
                <ClaysLogoSVG color={COLORS.forest} size={ButtonSize} />
                <h3>Make a booking</h3>
                <CloseButton
                  onClick={toggleSearchModal}
                  aria-label="Close booking search bar"
                />
              </Header>
              {searchBarConfig.map((item, index) => (
                <Accordion
                  key={item.title}
                  title={item.title}
                  isOpen={activeAccordion === item.title}
                  onClick={() => toggleAccordion(item.title)}
                  selectedValue={item.value}
                  error={searchErrors[item.title]}
                >
                  <item.content />
                  <ButtonWrapper error={searchErrors[item.title]}>
                    {searchErrors[item.title] && (
                      <ErrorMessage>
                        <ErrorSvg />
                        <span>Choose an option</span>
                      </ErrorMessage>
                    )}
                    {/* Render the Next button only if it's not the last item and there's no error */}
                    {index < searchBarConfig.length - 1 && (
                      <BookingFlowButton
                        onClick={() =>
                          toggleAccordion(searchBarConfig[index + 1].title)
                        }
                      >
                        Next
                      </BookingFlowButton>
                    )}
                  </ButtonWrapper>
                </Accordion>
              ))}
            </ModalContent>
            <Footer>
              <ClearButton onClick={() => clearSearchData()}>
                Clear all
              </ClearButton>
              <BookingFlowButton onClick={() => submitSearchData()}>
                Search
              </BookingFlowButton>
            </Footer>
          </Modal>
        </FocusTrap>
      )}
    </>
  );
};

export default SearchBarMobile;
