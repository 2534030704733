import Link from 'next/link';
import styled from 'styled-components';

import BREAKPOINTS from 'styles/breakpoints';

const Logo = styled.span`
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  z-index: 10;

  ${BREAKPOINTS.medium`
    width: 80px;
    height: 80px;
  `}
`;

const Img = styled.img`
  width: inherit;
  height: auto;
`;

const ClaysLogo = ({ className }) => {
  return (
    <Link href={'/'} passHref className="w-fit">
      <Logo className={className} aria-label="Home">
        <Img
          src="/images/logo-white.svg"
          alt="Clays logo"
          width={80}
          height={80}
        />
      </Logo>
    </Link>
  );
};

export default ClaysLogo;
