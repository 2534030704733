import styled, { css } from 'styled-components';

import { useBookingFlowManager } from 'context/bookingFlow';

import COLORS from 'styles/colors';
import BREAKPOINTS from 'styles/breakpoints';

const Bar = styled.div`
  width: 100%;
  height: 0.25rem;
  background-color: ${COLORS.forestBlack.opacity(0.1)};

  ${(props) =>
    (props.step === 'confirmation' || props.isLoading) &&
    css`
      display: none;
    `}

  ${BREAKPOINTS.medium`
    background-color: ${COLORS.white};
  `}
`;

const Progress = styled.div`
  height: 100%;
  width: ${(props) => props.progress}%;
  border-radius: 0rem 6.25rem 6.25rem 0rem;
  background-color: ${COLORS.dawn};
`;

const ProgressBar = ({ progress }) => {
  const { state, isLoadingScreen } = useBookingFlowManager();
  const currentStep = state.value;

  return (
    <Bar step={currentStep} isLoading={isLoadingScreen}>
      <Progress progress={progress} />
    </Bar>
  );
};

export default ProgressBar;
