import * as React from 'react';
import styled, { css } from 'styled-components';
import type { StateValue } from 'xstate';

import ProgressBar from './ProgressBar';
import { useNav } from 'src/context/NavProvider';
import ClaysLogoSVG from '../ClaysLogoSVG';
import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';
import BREAKPOINTS from 'styles/breakpoints';
import { useBookingFlowManager } from 'context/bookingFlow';

interface StyleProps {
  step?: StateValue;
  isLoading?: boolean;
}

const Nav = styled.nav<StyleProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${COLORS.white};

  ${(props) =>
    props.step === 'confirmation' &&
    css`
      background-color: ${COLORS.tealMuted};
    `}

  ${(props) =>
    props.isLoading &&
    css`
      background-color: ${COLORS.forest};
    `}

  ${BREAKPOINTS.medium`
    background-color: ${COLORS.forest};
    flex-direction: column-reverse;
     ${(props) =>
       props.step === 'confirmation' &&
       css`
         background-color: transparent;
         position: absolute;
       `}
  `}
`;

const StepHeading = styled.h1<StyleProps>`
  font-family: ${FONTS.poppins};
  font-size: 1rem;
  font-weight: 600;
  visibility: show;

  ${BREAKPOINTS.medium`
  visibility: hidden;
`}
`;

const NavContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 0.75rem 0.25rem;
  width: 100%;

  > *:nth-child(1) {
    justify-self: start;
    display: flex;
    align-items: center;
  }

  > *:nth-child(2) {
    justify-self: center;
  }

  > *:nth-child(3) {
    justify-self: end;
  }

  ${BREAKPOINTS.medium`
      padding: 2rem 2rem 0 2rem;
  `}
`;

const ButtonSize = '3rem';

const BackButton = styled.button`
  display: block;
  height: ${ButtonSize};
  width: ${ButtonSize};
  background-image: url('/images/icons/arrow-left.svg');
  background-size: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;

  &:focus-visible {
    outline: 1px auto -webkit-focus-ring-color;
  }

  ${BREAKPOINTS.medium`
  display: none;
  `}
`;

const ClaysLogo = styled(ClaysLogoSVG)<StyleProps>`
  display: none;

  ${BREAKPOINTS.medium`
    display: block;
      ${(props) =>
        props.step === 'confirmation' &&
        css`
          path {
            fill: ${COLORS.forestDark};
          }

          circle {
            stroke: ${COLORS.forestDark};
          }
        `}
  `}
`;

const CloseButton = styled.button<StyleProps>`
  height: ${ButtonSize};
  width: ${ButtonSize};
  background-image: url('/images/icons/cross.svg');
  background-size: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;

  &:focus-visible {
    outline: 1px solid -webkit-focus-ring-color;
  }

  ${BREAKPOINTS.medium`
  background-image: url('/images/icons/cross-white.svg');
   ${(props) =>
     props.step === 'confirmation' &&
     css`
       background-image: url('/images/icons/cross-dark.svg');
     `}
  `}
`;

type BookingHeaderProps = {
  progress: number;
  header: string | null;
};
const BookingHeader = ({ progress, header }: BookingHeaderProps) => {
  const { setIsExitBookingAlertOpen, setIsBookingModalOpen } = useNav();
  const { send, shouldShowBackButton, state, isLoadingScreen } =
    useBookingFlowManager();

  const currentStep = state.value;

  // Shows exit alert to user
  // closes booking modal if user is on confirmation step
  const closeBookingModal = () => {
    if (currentStep === 'confirmation') {
      return setIsBookingModalOpen(false);
    }
    setIsExitBookingAlertOpen(true);
  };

  return (
    <Nav step={currentStep} isLoading={isLoadingScreen}>
      <NavContent>
        <div>
          {shouldShowBackButton && (
            <BackButton
              onClick={() => {
                send({ type: 'onPrevStep' });
              }}
              aria-label="Previous booking step"
            />
          )}
          <ClaysLogo color={COLORS.white} size={'5rem'} step={currentStep} />
        </div>
        <StepHeading step={currentStep}>{header}</StepHeading>
        <CloseButton
          step={currentStep}
          onClick={closeBookingModal}
          aria-label="Exit booking flow"
        />
      </NavContent>
      <ProgressBar progress={progress} />
    </Nav>
  );
};

export default BookingHeader;
