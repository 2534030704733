import React from 'react';
import GPTWBagdeMono from 'public/images/badges/great-place-to-work-badge-mono.png';

const Badges = () => {
  return (
    <div className="flex items-center">
      <img src={GPTWBagdeMono.src} className="h-[150px]" />
    </div>
  );
};

export default Badges;
