import Head from 'next/head';

import COLORS from 'styles/colors';

import { WEBSITE_URL } from 'utils/constants';

const Meta = ({ pageTitle, description, keywords, ogLocale, previewImage }) => {
  const SITE_NAME = 'Clays bar';
  return (
    <Head>
      <title key="title">{pageTitle}</title>
      <meta key="x-ua" httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width,initial-scale=1"
      />

      {process.env.NEXT_PUBLIC_BUILD_ENV !== 'production' && (
        <meta name="robots" content="noindex" />
      )}

      <meta key="author" name="author" content="NEVERBLAND" />
      <meta key="description" name="description" content={description} />
      <meta key="keywords" name="keywords" content={keywords} />

      <meta key="og.locale" property="og:locale" content={ogLocale} />
      <meta key="og.type" property="og:type" content="website" />
      <meta key="og.site_name" property="og:site_name" content={SITE_NAME} />

      <meta key="og.title" property="og:title" content={pageTitle} />
      <meta key="og.url" property="og:url" content={WEBSITE_URL} />
      <meta
        key="og.description"
        property="og:description"
        content={description}
      />

      {previewImage && (
        <>
          <meta key="og.image" property="og:image" content={previewImage.url} />
          <meta key="og.image.width" property="og:image:width" content="1200" />
          <meta
            key="og.image.height"
            property="og:image:height"
            content="630"
          />
        </>
      )}

      <meta
        key="twitter.card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta key="twitter.title" name="twitter:title" content={pageTitle} />
      <meta
        key="twitter.description"
        name="twitter:description"
        content={description}
      />
      {previewImage && (
        <meta
          key="twitter.image"
          name="twitter:image"
          content={previewImage.url}
        />
      )}

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/favicon/android-chrome-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="256x256"
        href="/favicon/android-chrome-256x256.png"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color={COLORS.forest}
      />
      <link rel="shortcut icon" href="/favicon.ico" />
      <meta name="msapplication-TileColor" content={COLORS.forest} />
      <meta
        name="msapplication-TileImage"
        content="/favicon/mstile-150x150.png"
      />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <meta name="theme-color" content={COLORS.forest} />
      <meta
        name="google-site-verification"
        content="JpHjLo3phxdo__a8hNlRl_vkINN-yA5D9pVl2v8hejk"
      />
    </Head>
  );
};

export default Meta;
