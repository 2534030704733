import { Link } from 'components/Link';
import styled, { css } from 'styled-components';

import BREAKPOINTS from 'styles/breakpoints';
import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';
import TYPO, { rem, weight } from 'styles/typography';
import { useBookButton } from 'src/hooks/useBookButton';

const SIZES = {
  xSmall: {
    mobile: 70,
    desktop: 90,
  },
  small: {
    mobile: 90,
    desktop: 90,
  },
  medium: {
    mobile: 154,
    desktop: 180,
  },
  large: {
    mobile: 162,
    desktop: 300,
  },
};

const LinkStyled = styled.a`
  padding: ${({ size }) => SIZES[size].mobile / 10}px;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.dawn};
  background-clip: content-box;
  border-radius: 100%;
  border: 1px solid ${COLORS.dawn.opacity(0.3)};
  text-decoration: none;
  box-sizing: content-box;
  transform: rotate(10deg);
  transition: 0.6s;

  ${BREAKPOINTS.small`
    padding: ${({ size }) => SIZES[size].desktop / 10}px;
  `}
`;

const Mask = styled(LinkStyled)`
  background-color: ${COLORS.forest};
  border-color: ${COLORS.forest.opacity(0.3)};
  clip-path: circle(0%);
  pointer-events: none;
  cursor: pointer;
`;

const Label = styled.span`
  color: ${COLORS.white};
  font-family: ${FONTS.wulkan};
  font-weight: ${weight.black};
  text-align: center;

  ${({ size }) =>
    size === 'xSmall' &&
    css`
      max-width: 50px;
      font: ${weight.semibold} ${rem(11)} / ${rem(11)} ${FONTS.poppins};
      text-transform: uppercase;

      ${BREAKPOINTS.small`
        font-size: ${rem(14)};
        line-height: ${rem(14)};
      `}
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      max-width: 50px;
      font: ${weight.semibold} ${rem(14)} / ${rem(14)} ${FONTS.poppins};
      text-transform: uppercase;
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      ${TYPO.label}
      max-width: 120px;
    `}


  ${({ size }) =>
    size === 'large' &&
    css`
      ${TYPO.label}

      ${BREAKPOINTS.small`
        font-size: ${rem(30)};
        line-height: ${rem(36)};
      `}
    `}
`;

const Container = styled.div`
  padding: 15px;
  position: relative;
  width: ${({ size }) => SIZES[size].mobile}px;
  height: ${({ size }) => SIZES[size].mobile}px;
  box-sizing: content-box;

  &:hover ${Mask} {
    clip-path: circle(100%);
    transform: rotate(20deg);
  }

  ${BREAKPOINTS.small`
    width: ${({ size }) => SIZES[size].desktop}px;
    height: ${({ size }) => SIZES[size].desktop}px;
  `}
`;

const ButtonBase = css`
  padding: ${({ size }) => SIZES[size].mobile / 10}px;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ size }) => SIZES[size].mobile}px;
  height: ${({ size }) => SIZES[size].mobile}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.dawn};
  background-clip: content-box;
  border-radius: 100%;
  border: 1px solid ${COLORS.dawn.opacity(0.3)};
  text-decoration: none;
  box-sizing: content-box;
  transform: rotate(10deg);
  transition: 0.6s;

  ${BREAKPOINTS.small`
    padding: ${({ size }) => SIZES[size].desktop / 10}px;
    width: ${({ size }) => SIZES[size].desktop}px;
    height: ${({ size }) => SIZES[size].desktop}px;
  `}
`;

const StyledLink = styled.span`
  ${ButtonBase}
`;

const StyledButton = styled.button`
  ${ButtonBase}
`;

const MaskLabel = styled(Label)`
  color: ${COLORS.pinkMillennial};
`;

const BookButton = ({ label, href, size = 'small', ...props }) => {
  const { handleBookButtonClick } = useBookButton();

  return (
    <Container size={size} {...props}>
      {href ? (
        <Link href={href} passHref>
          <StyledLink size={size}>
            <Label size={size}>{label}</Label>
          </StyledLink>
        </Link>
      ) : (
        <StyledButton size={size} onClick={handleBookButtonClick}>
          <Label size={size}>{label || 'Book now'}</Label>
        </StyledButton>
      )}

      <Mask size={size}>
        <MaskLabel size={size}>{label || 'Book now'}</MaskLabel>
      </Mask>
    </Container>
  );
};

export default BookButton;
