import { useForm } from 'react-hook-form';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { Input } from '../ui/input';
import { Separator } from '../ui/separator';
import { Checkbox } from '../ui/checkbox';
import { Button } from '../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Calendar } from '../ui/calendar';
import { CalendarIcon } from 'lucide-react';
import { cn } from 'src/lib/utils';
import { format } from 'date-fns';
import Link from 'next/link';
import { useState } from 'react';
import { postData } from 'src/utils/api';
import { errorMsg } from 'utils/error-messages';

const optInSchema = z.object({
  firstName: z.string().min(1, { message: 'Please enter your first name' }),
  lastName: z.string().min(1, { message: 'Please enter your last name' }),
  email: z
    .string()
    .email({ message: 'Please enter a valid email address' })
    .min(1, { message: 'Please enter your email address' }),
  dob: z.date().refine((value) => {
    if (value > new Date()) {
      return {
        message: 'Please enter a valid date of birth',
      };
    }

    if (value < new Date('1900-01-01')) {
      return {
        message: 'Please enter a valid date of birth',
      };
    }

    return true;
  }),
  privacyPolicy: z.boolean().refine((value) => value, {
    message: 'Please accept the privacy policy',
  }),
});

type OptInFormValues = z.infer<typeof optInSchema>;

export default function MarketingForm() {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const form = useForm<OptInFormValues>({
    resolver: zodResolver(optInSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      dob: undefined,
      privacyPolicy: false,
    },
    mode: 'onSubmit',
  });

  async function onSubmit(data: OptInFormValues) {
    setError(null);
    setSubmitting(true);

    try {
      const { firstName, lastName, email, dob } = data;
      const formData = {
        firstName,
        lastName,
        email,
        dob: format(dob, "yyyy-MM-dd'T'HH:mm:ss.SSS"),
      };

      const res = await postData('/api/contact/marketing', {
        ...formData,
        sourceId: 'Website',
      });

      if (res.success) {
        form.reset();
        setSuccess('Thank you for signing up!');
      }

      if (res.error) {
        setError(errorMsg.resubmitForm);
      }
    } catch (error) {
      setError(errorMsg.resubmitForm);
    }

    return setSubmitting(false);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="w-full max-w-[340px] flex flex-col relative gap-4"
      >
        <label className="text-white text-xs">
          Opt in to receive the latest news from Clays, exclusive offers and
          discounts tailored to you, a special birthday treat and so much more!
        </label>

        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <Input
                  placeholder="Enter your first name"
                  {...field}
                  className="bg-white"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  placeholder="Enter your last name"
                  {...field}
                  className="bg-white"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="dob"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={'outline'}
                    className={cn(
                      'justify-start text-left font-normal bg-white',
                      !field.value && 'text-muted-foreground'
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {field.value ? (
                      format(field.value, 'PPP')
                    ) : (
                      <span>Date of birth</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent align="start" className=" w-auto p-0">
                  <Calendar
                    mode="single"
                    captionLayout="dropdown-buttons"
                    selected={field.value}
                    onSelect={field.onChange}
                    fromYear={1960}
                    toYear={2030}
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />

        <Separator className="bg-background/10" />

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  placeholder="Enter your email"
                  {...field}
                  className="bg-white"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="privacyPolicy"
          render={({ field }) => (
            <FormItem>
              <div className="flex items-start">
                <FormControl>
                  <Checkbox
                    className="bg-white checked:bg-blue-500"
                    id="privacy-policy"
                    checked={field.value as boolean}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>

                <FormLabel className="font-normal text-white text-xs pl-2">
                  I have read and accept the terms of processing data in the{' '}
                  <Link href="/privacy-policy" target="_blank">
                    Privacy Policy.
                  </Link>
                </FormLabel>
              </div>

              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" className="primaryCTA w-fit ml-auto">
          Opt in
        </Button>
      </form>
    </Form>
  );
}
