import React from 'react';

import styled, { css } from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

import { useBookingSearch } from 'context/BookingSearchProvider';
import { useNav } from 'context/NavProvider';

import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5625rem;
  min-width: 700px;
  background-color: ${COLORS.white};
  border: none;
  cursor: pointer;
  border-radius: 100px;
  box-shadow: 1px 1px 8px 0px ${COLORS.darkGrey.opacity(0.5)};
`;

const SectionContent = styled.div`
  text-align: left;
  padding-left: 24px;
  font-family: ${FONTS.poppins};
  border-right: 0.5px solid ${COLORS.grey};

  div {
    white-space: nowrap;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    color: ${COLORS.darkGrey};
  }

  span {
    font-size: 10px;
    margin: 0;
    color: #737373;
    display: block;

    ${({ error }) =>
      error &&
      css`
        color: red;
      `}
  }
`;

const Section = styled(Popover.Trigger)`
  padding: 14px 0 15px;
  height: 100%;

  &[data-state='open'] {
    ${SectionContent} {
      border: none;
    }
  }

  :focus-visible {
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

const SectionWrapper = styled.div`
  border-radius: 100px;
  background-color: ${(props) => props.active && COLORS.tealMuted};
  transition: all 0.2s ease;
  height: 100%;

  ${({ beforeActive }) =>
    beforeActive &&
    css`
      ${SectionContent} {
        border-right: none;
      }
    `};

  &:nth-of-type(1) {
    ${SectionContent} {
      padding-right: 51px;
    }

    ${Section} {
      width: 154px;
    }
  }

  &:nth-of-type(2) {
    ${SectionContent} {
      padding-right: 29px;
    }

    ${Section} {
      width: 132px;
    }
  }

  &:nth-of-type(3) {
    ${SectionContent} {
      padding-right: 24px;
    }

    ${Section} {
      width: 100px;
    }
  }

  &:nth-of-type(4) {
    display: flex;
    align-items: center;

    ${SectionContent} {
      border-right: none;
      padding-right: 59px;

      /* Truncate long values for the occasion section */
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 15ch;

        ${({ error }) =>
          error &&
          css`
            color: red;
          `}
      }
    }
  }
`;

const SearchButton = styled.button`
  display: flex;
  gap: 8px;
  background-color: ${COLORS.dawn};
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  font-family: ${FONTS.poppins};
  color: ${COLORS.white};
  border-radius: 160px;
  margin-right: 9px;

  :focus-visible {
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

const MagnifyingGlassIcon = styled.img``;

const SectionDropdown = styled(Popover.Content)`
  padding: 16px;
  background-color: ${COLORS.white};
  width: 335px;
  border-radius: 24px;
  z-index: 200;
  margin-top: 8px;
  max-height: 470px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SearchBarDesktop = () => {
  const { searchBarConfig, searchErrors, submitSearchData } =
    useBookingSearch();
  const { activeSearchDropdown, setActiveSearchDropdown } = useNav();

  return (
    <SearchBar>
      {searchBarConfig.map((item, idx) => {
        return (
          <Popover.Root
            key={item.title}
            open={idx === activeSearchDropdown}
            onOpenChange={(open) => setActiveSearchDropdown(open ? idx : null)}
          >
            <SectionWrapper
              active={activeSearchDropdown === idx}
              beforeActive={idx === activeSearchDropdown - 1}
            >
              {/* Dropdown trigger */}
              <Section>
                <SectionContent error={searchErrors[item.title]}>
                  <div>
                    <p>{item.title}</p>
                    <span>{item.value || item.placeholder}</span>
                  </div>
                </SectionContent>
              </Section>

              {idx === searchBarConfig.length - 1 && (
                <SearchButton onClick={() => submitSearchData()}>
                  <MagnifyingGlassIcon
                    src="/images/icons/magnifying-glass.svg"
                    alt=""
                  />
                  Search
                </SearchButton>
              )}
            </SectionWrapper>

            {/* Dropdown */}
            <Popover.Portal>
              <SectionDropdown align={idx === 0 ? 'start' : 'center'}>
                <item.content />
              </SectionDropdown>
            </Popover.Portal>
          </Popover.Root>
        );
      })}
    </SearchBar>
  );
};

export default SearchBarDesktop;
