import Image from 'next/legacy/image';
import styled from 'styled-components';

import BookButton from 'components/Button/BookButton';
import ClaysLogo from 'components/ClaysLogo';

import { useClaysLocation } from 'context/ClaysLocationProvider';

import BREAKPOINTS from 'styles/breakpoints';
import { PageContainer } from 'styles/global';
import { rotate } from 'styles/animations';

const Header = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  ${BREAKPOINTS.small`
    top: 25px;
    left: 30px;
  `}
`;

const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 362px;
  height: 362px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${BREAKPOINTS.small`
    width: 412px;
    height: 412px;
  `}
`;

const ImageStyled = styled(Image)`
  animation: ${rotate} 40s linear infinite;
`;

const ErrorPage = () => {
  const { claysLocation } = useClaysLocation();
  return (
    <PageContainer theme="dark" bgRoundels={2}>
      <Header>
        <ClaysLogo />
      </Header>
      <Inner>
        <ImageWrapper>
          <ImageStyled
            src="/images/roundels/404-roundel.svg"
            alt="Decorative text around circle '404 Oops!'"
            layout="fill"
          />
          <BookButton
            label="Take me home"
            href={claysLocation ? `/${claysLocation}` : '/'}
          />
        </ImageWrapper>
      </Inner>
    </PageContainer>
  );
};

export default ErrorPage;
