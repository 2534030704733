import { useRouter } from 'next/router';

import { TawkScript } from 'components/ThirdParty';

import ErrorPage from 'pages/_error';
import Footer from 'components/Footer/Footer';
import Meta from 'components/Meta';
import NavBar from 'components/NavBar';
import { useNav } from 'src/context/NavProvider';
import { BookingFlow } from 'components/BookingFlow/BookingFlow';

import { isProduction } from 'utils/helpers';

const PageWrapper = ({
  page,
  children,
  hideNav = false,
  hideFooter = false,
}) => {
  const { isBookingModalOpen } = useNav();
  const { isFallback } = useRouter();
  const isLoading = isFallback;
  const isError = !isLoading && !page;

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <>
      <main>
        <Meta {...page?.metaData} />
        {!hideNav && <NavBar />}
        {isBookingModalOpen && <BookingFlow />}
        {children}
        {!hideFooter && <Footer />}
      </main>

      {isProduction() && <TawkScript />}
    </>
  );
};

export default PageWrapper;
