import * as React from 'react';
import styled from 'styled-components';

import COLORS from 'styles/colors';
import BREAKPOINTS from 'styles/breakpoints';

import { useNav } from 'src/context/NavProvider';

import BookingFlowButton from '../Button/BookingFlowButton';
import { datalayerPush } from '../ThirdParty/GtmDatalayerPush';

const Alert = styled.div`
  padding: 1.5rem;
  background-color: ${COLORS.white};
  color: ${COLORS.forestDark};
  border-radius: 0.5rem;
  max-width: 21rem;
  margin: 0 auto;

  ${BREAKPOINTS.medium`
    background-color: ${COLORS.sky};
    `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.1rem;
  font-weight: 600;
  font-size: 1rem;
`;

const AlertText = styled.p`
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
  text-align: center;
`;

const ExitButton = styled.button`
  background-color: transparent;
  color: inherit;
  border: none;
  font-weight: inherit;
  font-size: inherit;
  text-decoration: underline;
  text-underline-offset: 0.1rem;

  &:focus-visible {
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

const ExitBookingConfirmation = () => {
  const { setIsExitBookingAlertOpen, setIsBookingModalOpen } = useNav();

  const continueWithBooking = () => {
    setIsExitBookingAlertOpen(false);
    datalayerPush({
      event: 'booking_flow_continue',
    });
  };

  const exitBooking = () => {
    setIsBookingModalOpen(false);
    setIsExitBookingAlertOpen(false);
    datalayerPush({
      event: 'booking_flow_exit',
    });
  };

  return (
    <Alert>
      <Content>
        <AlertText>Are you sure you want to exit your booking?</AlertText>
        <BookingFlowButton onClick={continueWithBooking}>
          No, continue booking
        </BookingFlowButton>
        <ExitButton onClick={exitBooking}>Yes, exit</ExitButton>
      </Content>
    </Alert>
  );
};

export default ExitBookingConfirmation;
