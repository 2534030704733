import styled from 'styled-components';
import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';
import ErrorSvg from 'public/images/icons/error.svg';

const AccordionContainer = styled.div`
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  overflow: hidden;
  background-color: ${COLORS.white};
`;

const AccordionTrigger = styled.button`
  cursor: pointer;
  width: 100%;
  padding: ${({ isOpen }) => (isOpen ? '1rem 1rem 0 1rem' : '1rem')};
  border: none;
  outline: none;

  :focus-visible {
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

const TriggerContent = styled.div`
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${FONTS.poppins};
  color: ${COLORS.darkGrey};
`;

const AccordionContent = styled.div`
  max-height: ${({ isOpen }) => (isOpen ? 'none' : '0')};
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
  padding: ${({ isOpen }) => (isOpen ? '1rem' : '0 1rem')};
`;

const AccordionHeading = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 120%;
`;

const AccordionSelectedValue = styled.span`
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 140%;
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.orange};
  font-size: 0.8125rem;
`;

const Accordion = ({
  title,
  isOpen,
  onClick,
  children,
  selectedValue,
  error,
}) => {
  return (
    <AccordionContainer>
      <AccordionTrigger isOpen={isOpen} onClick={onClick}>
        <TriggerContent>
          <AccordionHeading>{title}</AccordionHeading>
          {error && (
            <ErrorMessage>
              <span>Choose an option</span>
              <ErrorSvg />
            </ErrorMessage>
          )}
          {selectedValue && (
            <AccordionSelectedValue>{selectedValue}</AccordionSelectedValue>
          )}
        </TriggerContent>
      </AccordionTrigger>
      <AccordionContent isOpen={isOpen}>{children}</AccordionContent>
    </AccordionContainer>
  );
};

export default Accordion;
