import { makeAutoObservable } from 'mobx';

class NavStore {
  disabledOnPaths = [
    '/events-faqs',
    '/successful-booking',
    '/group-bookings',
    '/thank-you',
    '/sign-in',
    '/reset-password',
  ];

  constructor() {
    makeAutoObservable(this);
  }

  public searchBarVisible() {
    if (typeof window === 'undefined') return false;
    const currentPath = window.location.pathname;
    return this.disabledOnPaths.find((item) => item === currentPath)
      ? false
      : true;
  }
}

export const navStore = new NavStore();
