import { useNav } from 'context/NavProvider';
import { breakpointSizes } from 'src/styles/breakpoints';

export const useBookButton = () => {
  const {
    burgerMenuIsOpen,
    setBurgerMenuIsOpen,
    setSearchModalOpen,
    setActiveSearchDropdown,
  } = useNav();

  const focusSearchBar = () => {
    if (window.innerWidth >= breakpointSizes.medium) {
      setActiveSearchDropdown(0);
    } else {
      setSearchModalOpen(true);
    }
  };

  const handleBookButtonClick = () => {
    if (burgerMenuIsOpen) {
      setBurgerMenuIsOpen(false);
    }

    focusSearchBar();
  };

  return { handleBookButtonClick };
};
