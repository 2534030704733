import * as React from 'react';

const ClaysLogoSVG = ({ color = 'currentColor', size = '80px', ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      strokeOpacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="none"
      stroke={color}
      cx="40"
      cy="40"
      r="39.4"
    />
    <circle
      fillRule="evenodd"
      clipRule="evenodd"
      fill="none"
      stroke={color}
      strokeWidth="2.5"
      cx="40"
      cy="40"
      r="35.6"
    />
    <path
      fill={color}
      d="M17.3,43.3c-0.8,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.9,0.3-1.7,0.9-2.3c0.5-0.6,1.3-1,2.2-1
		c1,0,2,0.5,2.5,1.4l1.8-1.2c-1-1.4-2.6-2.2-4.3-2.2c-1.4,0-2.8,0.5-3.7,1.6c-2,2.1-2,5.5,0,7.6c1,1,2.3,1.6,3.7,1.6
		c1.8,0,3.4-0.8,4.4-2.3L20,41.8C19.4,42.7,18.4,43.2,17.3,43.3z"
    />
    <path fill={color} d="M25.9,45.2V34.8h2.2v8.5h3.9v2H25.9z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M35.2,45.2h2.4l0.9-2.5h3.9l0.7,2.5h2.3l-3.9-10.4h-2.4L35.2,45.2z M40.3,37.2l1.1,3.6h-2.2L40.3,37.2z"
    />
    <path
      fill={color}
      d="M49.7,34.8l2.5,3.9l2.5-3.9h2.6l-4,5.7v4.7h-2.3v-4.7l-4-5.7H49.7z"
    />
    <path
      fill={color}
      d="M64.6,39.2l-1.2-0.4c-0.7-0.2-1.1-0.6-1.1-1.1c0-0.6,0.6-1,1.3-1c0.9,0,1.3,0.5,1.3,1.2h2.2c0-1-0.3-1.8-1-2.3
		c-0.7-0.6-1.5-0.9-2.5-0.9c-0.9,0-1.8,0.3-2.6,0.9c-0.7,0.5-1.1,1.4-1,2.2c0,1.4,0.9,2.4,2.7,3l1.3,0.3c1,0.3,1.3,0.7,1.3,1.2
		c0,0.7-0.6,1.1-1.5,1.1c-1,0-1.5-0.5-1.6-1.3h-2.2c0,0.9,0.4,1.8,1,2.3c0.7,0.6,1.6,0.9,2.7,0.9c1.3,0,2.4-0.4,3.2-1.3
		c0.4-0.5,0.6-1.1,0.6-1.7C67.5,40.8,66.6,39.7,64.6,39.2z"
    />
    <path
      fill={color}
      d="M24.1,29.8h0.7c0,0,0,0,0,0l0,0c0.7-1,1.4-1.9,2.3-2.7c1.7-1.7,3.8-3.1,6.1-4c2.2-0.9,4.5-1.3,6.9-1.3h0
		c2.4,0,4.7,0.5,6.9,1.4c2.3,0.9,4.3,2.3,6.1,4c0.8,0.8,1.6,1.7,2.2,2.7l0,0c0,0,0,0,0,0H56c0,0,0,0,0,0c0,0,0,0,0,0L56,29.6
		c-1.4-2.1-3.2-4-5.3-5.4c-2-1.4-4.3-2.3-6.7-2.8c-2.4-0.5-4.9-0.5-7.3-0.1c-2.5,0.4-4.9,1.4-7,2.8c-2.2,1.4-4.1,3.3-5.6,5.6
		L24.1,29.8C24,29.7,24,29.8,24.1,29.8L24.1,29.8C24.1,29.8,24.1,29.8,24.1,29.8z"
    />
    <path
      fill={color}
      d="M55.3,50.2h0.7l0,0c0,0,0,0,0,0c0,0,0,0,0,0L56,50.4c-1.4,2.2-3.3,4.1-5.6,5.6c-8.8,5.7-20.6,3.2-26.3-5.6
		L24,50.2c0,0,0,0,0,0c0,0,0,0,0,0h0.7c0,0,0,0,0,0l0,0c3.4,5.1,9.1,8.1,15.2,8.1h0c4.9,0,9.5-1.9,13-5.4
		C53.9,52.2,54.6,51.2,55.3,50.2L55.3,50.2C55.3,50.2,55.3,50.2,55.3,50.2z"
    />
  </svg>
);

export default ClaysLogoSVG;
