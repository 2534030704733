import React from 'react';

import { useClaysLocation } from 'src/context/ClaysLocationProvider';
import { formatSlugToString } from 'src/utils/helpers';
import { TOGGLE_URL } from 'src/utils/constants';

let sitemapItems = [
  { label: 'Member Login', href: '/my-clays' },
  { label: 'Careers', href: '/careers' },
  { label: 'Gift Cards', href: TOGGLE_URL },
  { label: 'Press & influencers', href: '/contact?subject=press' },
  { label: 'FAQs', href: '/faqs' },
  { label: 'Privacy Policy', href: '/privacy-policy' },
  { label: 'Terms & Conditions', href: '/terms-and-conditions' },
  {
    label: 'Cookie Settings',
    href: 'cookieConsentRevisit',
    className: 'cky-banner-element',
  },
];

const NewSitemap = () => {
  const { allClaysLocations } = useClaysLocation();

  const claysLocations = allClaysLocations.map(({ slug }) => {
    return {
      label: formatSlugToString(slug),
      href: `/${slug}`,
    };
  });

  const HALF = Math.ceil(sitemapItems.length / 2);
  const linksGroupOne = sitemapItems.slice(0, HALF);
  const linksGroupTwo = sitemapItems.slice(HALF, sitemapItems.length);

  return (
    <div className="flex items-start justify-center gap-4 md:gap-12 lg:gap-20 whitespace-nowrap">
      <div className="flex flex-col items-start gap-2">
        {linksGroupOne.map(({ label, href, className }) => (
          <SitemapLink
            key={label}
            href={href}
            label={label}
            className={className}
          />
        ))}
      </div>

      <div className="flex flex-col items-start gap-2">
        {linksGroupTwo.map(({ label, href, className }) => (
          <SitemapLink
            key={label}
            href={href}
            label={label}
            className={className}
          />
        ))}
      </div>

      <div className="flex flex-col items-start gap-2">
        {claysLocations.map(({ label, href }) => (
          <SitemapLink key={label} href={href} label={label} />
        ))}
      </div>
    </div>
  );
};

export default NewSitemap;

type SitemapLinkProps = {
  label: string;
  href: string;
  className?: string;
};

function SitemapLink({ label, href, className }: SitemapLinkProps) {
  const isExternal = href.startsWith('https');
  const isCookieConsentRevisit = href === 'cookieConsentRevisit';

  if (isCookieConsentRevisit) {
    return (
      <a
        className={`${className} text-[12px] leading-4 text-white no-underline font-normal hover:text-dawn transition-colors duration-300 ease-in-out`}
      >
        {label}
      </a>
    );
  }

  if (isExternal) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={`${className} text-[12px] leading-4 text-white no-underline font-normal hover:text-dawn transition-colors duration-300 ease-in-out`}
      >
        {label}
      </a>
    );
  }

  return (
    <a
      href={href}
      className="text-[12px] leading-4 text-white no-underline font-normal hover:text-dawn transition-colors duration-300 ease-in-out"
    >
      {label}
    </a>
  );
}
