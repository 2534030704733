import { signOut } from 'next-auth/react';

const signOutUser = async () => {
  await fetch('/api/auth/sign-out');

  await signOut({
    redirect: true,
    callbackUrl: '/',
  });
};

export default signOutUser;
