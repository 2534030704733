import React from 'react';

import Image from 'next/legacy/image';

const SOCIAL_LINKS = [
  { type: 'instagram', handle: 'clays_bar' },
  { type: 'tiktok', handle: '@clays.bar?is_from_webapp=1&sender_device=pc' },
  { type: 'facebook', handle: 'Clays_Bar-104504734653021' },
  { type: 'linkedin', handle: 'company/claysbar' },
];

export default function SocialLinks() {
  return (
    <div className="flex items-center gap-8">
      {SOCIAL_LINKS.map(({ type, handle }) => (
        <a
          key={type}
          href={`https://www.${type}.com/${handle}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center flex-shrink-0 border border-white/30 rounded-full w-12 h-12 hover:bg-white/10 active:bg-white/20 transition-colors duration-300 ease-in-out"
        >
          <Image
            src={`/images/icons/${type}.svg`}
            alt=""
            width={20}
            height={20}
          />
        </a>
      ))}
    </div>
  );
}
