import React, { useState } from 'react';
import styled from 'styled-components';
import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';
import BREAKPOINTS from 'styles/breakpoints';
import Image from "next/legacy/image";
import { formatDateTimeString, formatWhoValue } from 'utils/bookingSearchUtils';
import { useBookingFlowManager } from 'context/bookingFlow';

const Tab = styled.div`
  display: none;
  width: ${({ isOpen }) => (isOpen ? '21rem' : '14rem')};
  transition: width 0.25s ease;
  font-family: ${FONTS.poppins};
  flex-shrink: 0;

  ${BREAKPOINTS.medium`
    display: block;
    `}
`;

const Trigger = styled.button`
  width: 100%;
  font-family: ${FONTS.poppins};
  border: none;
  /* LAYOUT */
  display: flex;
  flex-direction: ${({ isOpen }) => (isOpen ? 'row-reverse' : 'row')};
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  /* STYLES */
  background-color: ${COLORS.forestBlack};
  border-radius: ${({ isOpen }) => (isOpen ? '0.5rem 0.5rem 0 0' : '0.5rem')};
  color: ${COLORS.white};
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid ${COLORS.sky};
  }
`;

const Arrow = styled(Image)`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Summary = styled.ul`
  /* LAYOUT */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
  width: 100%;
  padding: ${({ isOpen }) => (isOpen ? '0.5rem 1rem 1rem 1rem' : '0')};
  margin: 0;

  /* STYLES */
  border-radius: ${({ isOpen }) => (isOpen ? '0 0 0.5rem 0.5rem' : '0')};
  background-color: ${COLORS.white};
  transition: all 0.2s ease-in-out, padding 0.1s linear;
  overflow: hidden;
  color: ${COLORS.darkGrey};
  font-size: 0.8125rem;
  font-weight: 400;
`;

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
`;

const Price = styled.ul`
  margin-top: 1rem;
  list-style-type: none;
  padding: 0;
`;

const Total = styled.li`
  font-size: 1rem;
  font-weight: 600;
`;

const PerPerson = styled.li`
  font-size: 0.8125rem;
`;

const SummaryTabDesktop = ({ className = '' }) => {
  const {
    searchData,
    selectedTimeSlot,
    totalGameDuration,
    totalBookingCost,
    selectedPackageCost,
    selectedAddOnsCost,
    booking,
  } = useBookingFlowManager();

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Tab isOpen={isOpen} className={className}>
      <Trigger isOpen={isOpen} onClick={toggleOpen}>
        <Arrow
          src="/images/icons/chevron-white.svg"
          alt="Toggle summary"
          width={24}
          height={24}
          isOpen={isOpen}
        />
        <span>Booking Summary</span>
      </Trigger>
      <Summary isOpen={isOpen}>
        <Item>
          <span>Game booking fee</span>
          <span>£{totalBookingCost.total.toFixed(2)}</span>
        </Item>
        <Item>
          <span>Where</span>
          <span>{booking.venueName}</span>
        </Item>
        <Item>
          <span>When</span>
          <span>
            {selectedTimeSlot.timeIso
              ? formatDateTimeString(selectedTimeSlot.timeIso)
              : ''}
          </span>
        </Item>
        <Item>
          <span>Who</span>
          <span>{formatWhoValue(searchData.Who)}</span>
        </Item>
        <Item>
          <span>Occasion</span>
          <span>{searchData.Occasion.name}</span>
        </Item>
        <Item>
          <span>Playing time</span>
          <span>{totalGameDuration} minutes</span>
        </Item>
        <Item>
          <span>Packages & upgrades</span>
          <span>£{(selectedPackageCost + selectedAddOnsCost).toFixed(2)}</span>
        </Item>
        <Price>
          <Total>Total: £{totalBookingCost.total.toFixed(2)}</Total>
          <PerPerson>
            Per person: £{totalBookingCost.perPerson.toFixed(2)}
          </PerPerson>
        </Price>
      </Summary>
    </Tab>
  );
};

export default SummaryTabDesktop;
