import * as React from 'react';
import styled from 'styled-components';

import BREAKPOINTS, { breakpointSizes } from 'styles/breakpoints';
import {
  useApiErrorMessage,
  useBookingFlowManager,
} from 'src/context/bookingFlow';

import Nudge from './Nudge';

const Container = styled.div`
  padding: 1rem;
  padding-bottom: 0;
  ${BREAKPOINTS.medium`
          padding: 1.5rem;
          padding-bottom: 0;
    `}
`;

export const BookingFlowApiError = () => {
  const message = useApiErrorMessage();
  const { isLoadingScreen } = useBookingFlowManager();

  if (!message || isLoadingScreen) {
    return null;
  }

  return (
    <Container>
      <Nudge text={message} name="error" />
    </Container>
  );
};
