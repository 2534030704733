import * as React from 'react';
import styled from 'styled-components';
import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';
import { weight } from 'styles/typography';
import { formatDateTimeString } from 'utils/bookingSearchUtils';
import { useBookingFlowManager } from 'context/bookingFlow';

const Tab = styled.div`
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  width: 100%;
  transition: transform 0.3s ease-in-out;
  font-family: ${FONTS.poppins};
`;

const ButtonSize = '2.5rem';
const CloseTrigger = styled.button`
  border: none;
  display: flex;
  align-items: center;
  position: absolute;
  top: -1.25rem;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${ButtonSize};
  width: ${ButtonSize};
  padding: 0.5rem;
  border-radius: 50%;
  background-color: ${COLORS.white};

  background-image: url(/images/icons/chevron-centered.svg);
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
`;

const Container = styled.div`
  background-color: ${COLORS.white};
  padding: 1.5rem 1.25rem 0 1.25rem;
`;

const GameBooking = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 0.3rem;
  font-size: 1rem;
  font-weight: ${weight.semibold};
`;

const Summary = styled.ul`
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Item = styled.li`
  font-size: 0.8125rem;
`;

const ItemLabel = styled.label`
  font-weight: ${weight.semibold};
  &:after {
    content: ': ';
  }
`;

const SummaryTabMobile = ({ isOpen, onClick, className = '' }) => {
  const {
    searchData,
    selectedTimeSlot,
    totalGameDuration,
    totalBookingCost,
    selectedPackageCost,
    selectedAddOnsCost,
    booking,
  } = useBookingFlowManager();

  if (!selectedTimeSlot) {
    return null;
  }

  return (
    <Tab isOpen={isOpen} className={className}>
      <CloseTrigger onClick={onClick}></CloseTrigger>
      <Container>
        <GameBooking>
          <label>Game booking fee</label>
          <span>£{totalBookingCost.total.toFixed(2)}</span>
        </GameBooking>
        <Summary>
          <Item>
            <ItemLabel>Where</ItemLabel>
            <span>{booking.venueName}</span>
          </Item>
          <Item>
            <ItemLabel>When</ItemLabel>
            <span>
              {selectedTimeSlot.timeIso
                ? formatDateTimeString(selectedTimeSlot.timeIso)
                : ''}
            </span>
          </Item>
          <Item>
            <ItemLabel>Who</ItemLabel>
            <span>{searchData.Who} people</span>
          </Item>
          <Item>
            <ItemLabel>Occasion</ItemLabel>
            <span>{searchData.Occasion.name}</span>
          </Item>
          <Item>
            <ItemLabel>Playing time</ItemLabel>
            <span>{totalGameDuration} minutes</span>
          </Item>
          <Item>
            <ItemLabel>Packages & upgrades</ItemLabel>
            <span>
              £{(selectedPackageCost + selectedAddOnsCost).toFixed(2)}
            </span>
          </Item>
        </Summary>
      </Container>
    </Tab>
  );
};

export default SummaryTabMobile;
