import * as React from 'react';
import styled, { css } from 'styled-components';
import type { StateValue } from 'xstate';

import SummaryTabMobile from './SummaryTabMobile';
import BREAKPOINTS from 'styles/breakpoints';
import BookingFlowButton from '../Button/BookingFlowButton';
import COLORS from 'styles/colors';
import ChevronSvg from 'public/images/icons/chevron-centered.svg';
import FONTS from 'styles/fonts';
import { formatDateTimeString } from 'utils/bookingSearchUtils';
import { useBookingFlowManager } from 'context/bookingFlow';

interface StyleWithStep {
  step: StateValue;
}

const Footer = styled.nav<StyleWithStep>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 5.5rem;
  justify-content: space-between;
  margin-top: auto;
  padding: 1.5rem;
  background-color: ${COLORS.white};
  box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.15);

  ${(props) =>
    props.step === 'confirmation' &&
    css`
      display: none;
    `}

  ${BREAKPOINTS.medium`
    display: none;
  `}
`;

const SelectedTimeSlot = styled.div`
  position: absolute;
  left: 0;
  top: -3rem;

  height: 3rem;
  width: 100%;
  display: flex;
  padding: 0.88rem 1.5rem;
  background-color: ${COLORS.tealMuted};
  font-size: 0.8125rem;
  color: ${COLORS.forestDark};
`;

const Chevron = styled(ChevronSvg)`
  path {
    stroke-width: 5px;
    fill: ${COLORS.forestDark};
  }
`;

const SummaryTabTrigger = styled.button`
  position: absolute;
  left: 0;
  top: -1.25rem;
  border: none;
  display: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? 'none' : 'flex')};

  &:focus-visible {
    outline: 1px auto -webkit-focus-ring-color;
  }

  color: ${COLORS.forestDark};
  align-items: center;
  left: 0;
  font-family: ${FONTS.poppins};

  /* LAYOUT */
  justify-content: space-between;
  gap: 0.75rem;
  height: 2.5rem;
  padding: 0.5rem 1rem 0.5rem 1.19rem;
  max-width: 8rem;

  /* STYLES */
  border: none;
  background-color: ${COLORS.white};
  border-radius: 10rem;
  font-size: 0.8125rem;
  font-weight: 400;
`;

const SummaryTab = styled(SummaryTabMobile)`
  position: fixed;
  bottom: 5.5rem;
  left: 0;
`;

const Price = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const Total = styled.li`
  font-size: 1rem;
  font-weight: 600;
`;

const PerPerson = styled.li`
  font-size: 0.8125rem;
`;

const NextButton = styled(BookingFlowButton)`
  margin-left: auto;
`;

type BookingFooterMobileProps = {
  className?: string;
};
const BookingFooterMobile = ({ className }: BookingFooterMobileProps) => {
  const {
    shouldShowBookingSummary,
    totalBookingCost,
    selectedTimeSlot,
    shouldShowNextButton,
    nextButtonDisabled,
    nextButtonText,
    onNextStep,
    state,
  } = useBookingFlowManager();
  const [summaryIsOpen, setSummaryIsOpen] = React.useState(false);

  const toggleSummaryOpen = () => {
    setSummaryIsOpen(!summaryIsOpen);
  };

  const currentStep = state.value;

  return (
    <Footer className={className} step={currentStep}>
      {state.matches('time') && selectedTimeSlot && (
        <SelectedTimeSlot>
          Selected: {formatDateTimeString(selectedTimeSlot.timeIso)}
        </SelectedTimeSlot>
      )}
      {shouldShowBookingSummary && (
        <>
          <SummaryTabTrigger onClick={toggleSummaryOpen} isOpen={summaryIsOpen}>
            <span>Summary</span>
            <Chevron />
          </SummaryTabTrigger>
          <SummaryTab onClick={toggleSummaryOpen} isOpen={summaryIsOpen} />
        </>
      )}
      <Price>
        <Total>Total: £{totalBookingCost.total.toFixed(2)}</Total>
        <PerPerson>
          Per person: £{totalBookingCost.perPerson.toFixed(2)}
        </PerPerson>
      </Price>
      {shouldShowNextButton && (
        <NextButton onClick={onNextStep} disabled={nextButtonDisabled}>
          {nextButtonText}
        </NextButton>
      )}
    </Footer>
  );
};

export default BookingFooterMobile;
