import * as React from 'react';
import styled, { css } from 'styled-components';
import FocusTrap from 'focus-trap-react';
import type { StateValue } from 'xstate';
import { useBookingFlowManager, useStepContent } from 'context/bookingFlow';
import { useBookingSearch } from 'context/BookingSearchProvider';
import { useNav } from 'src/context/NavProvider';
import BREAKPOINTS from 'styles/breakpoints';
import COLORS from 'styles/colors';
import FONTS from 'styles/fonts';
import BookingHeader from './BookingHeader';
import SummaryTabDesktop from './SummaryTabDesktop';
import BookingFooterMobile from './BookingFooterMobile';
import BookingFooterDesktop from './BookingFooterDesktop';
import ExitBookingConfirmation from './ExitBookingConfirmation';
import { useInSyncRef } from 'src/hooks/useInSyncRef';
import { BookingFlowApiError } from './BookingFlowApiError';

interface StyleProps {
  step: StateValue;
  hasExitAlert?: boolean;
  isLoading?: boolean;
}

const Modal = styled.div<StyleProps>`
  position: fixed;
  inset: 0;
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  overflow: hidden;
  overflow-y: auto;

  background-color: ${COLORS.sky};
  color: ${COLORS.forestBlack};

  ${(props) =>
    props.step === 'confirmation' &&
    css`
      background-color: ${COLORS.tealMuted};
    `}

  ${(props) =>
    props.hasExitAlert &&
    css`
      background-color: ${COLORS.forest};
      justify-content: center;
    `}

  ${BREAKPOINTS.medium`
    background-color: ${COLORS.forest};
    color: ${COLORS.white};
    ${(props) =>
      props.step === 'confirmation' &&
      css`
        background-color: ${COLORS.tealMuted};
      `}
  `}

  ${BREAKPOINTS.large`
  align-items: center;
  `}
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${BREAKPOINTS.medium`
    margin-left: 2rem;
    max-width: 45.875rem;
  `}
`;

const StepHeading = styled.h1<StyleProps>`
  display: none;

  ${(props) =>
    props.step === 'confirmation' &&
    css`
      display: none;
    `}

  ${BREAKPOINTS.medium`
  font-family: ${FONTS.wulkan};
  font-size: 2.625rem;  
  font-weight: 850;
  color: ${COLORS.white};
  display: block;
  margin: 2rem 0;

  ${(props) =>
    props.step === 'confirmation' &&
    css`
      display: none;
    `}
`}
`;

const StepComponentWrapper = styled.div<StyleProps>`
  width: 100%;
  margin-bottom: 5.5rem;

  ${(props) =>
    props.step === 'confirmation' &&
    css`
      margin-bottom: 0;
    `}

  ${(props) =>
    props.isLoading &&
    css`
      margin-bottom: 0;
      padding-bottom: 0;
      background-color: ${COLORS.forest};
    `}

  ${BREAKPOINTS.medium`
  margin-bottom: 0;
  background-color: ${COLORS.sky};
  color: ${COLORS.darkGrey};
  padding-bottom: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 3rem;

  ${(props) =>
    props.isLoading &&
    css`
      margin-bottom: 0;
      padding-bottom: 0;
      background-color: ${COLORS.forest};
    `}

  ${(props) =>
    props.step === 'confirmation' &&
    css`
      margin-bottom: 0;
      padding-bottom: 0;
      background-color: transparent;
      border-radius: 0;
    `}
`}
`;

const StyledSummaryTab = styled(SummaryTabDesktop)`
  position: absolute;
  top: 9.25rem;
  right: 0;
`;

const FooterMobile = styled(BookingFooterMobile)`
  position: fixed;
  bottom: 0;
`;

export const BookingFlow = () => {
  const { searchData, clearSearchData } = useBookingSearch();
  const { isExitBookingAlertOpen, isBookingModalOpen } = useNav();

  // Use a ref here so we don't re-trigger the useEffect below.
  const clearSearchDataStableRef = useInSyncRef(clearSearchData);

  const {
    progress,
    send,
    shouldShowBookingSummary,
    shouldShowNavigation,
    state,
    isLoadingScreen,
  } = useBookingFlowManager();

  const { header, content } = useStepContent();

  const currentStep = state.value;
  const shouldShowExitBookingAlert =
    isExitBookingAlertOpen && currentStep !== 'confirmation';

  // Use a ref here so we don't re-trigger the useEffect below
  const initialSearchData = useInSyncRef(searchData);

  // Store the previous state value in a ref
  const prevState = React.useRef(currentStep);

  React.useEffect(() => {
    send({
      type: 'onInitialSearchData',
      searchData: initialSearchData.current,
    });

    const resetSearch = clearSearchDataStableRef.current;

    return () => {
      // Only clear search data if the last state was 'confirmation'
      if (prevState.current === 'confirmation') {
        resetSearch();
      }
      send({ type: 'onResetFlow' });
    };
  }, [initialSearchData, send, clearSearchDataStableRef]);

  // Update prevState ref after state.value changes
  React.useEffect(() => {
    prevState.current = currentStep;
  }, [currentStep]);

  // For debug
  // useTestPlayer();

  return (
    <FocusTrap active={isBookingModalOpen}>
      <Modal step={currentStep} hasExitAlert={isExitBookingAlertOpen}>
        {!isExitBookingAlertOpen && (
          <>
            <BookingHeader header={header} progress={progress} />
            <StepContainer>
              {!isLoadingScreen && (
                <StepHeading step={currentStep}>{header}</StepHeading>
              )}
              <StepComponentWrapper
                step={currentStep}
                isLoading={isLoadingScreen}
              >
                <BookingFlowApiError />
                {content}
                {shouldShowNavigation && <BookingFooterDesktop />}
              </StepComponentWrapper>
            </StepContainer>
            {shouldShowNavigation && (
              <>
                {shouldShowBookingSummary && <StyledSummaryTab />}
                <FooterMobile />
              </>
            )}
          </>
        )}

        {shouldShowExitBookingAlert && <ExitBookingConfirmation />}
      </Modal>
    </FocusTrap>
  );
};
